import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

function importAll(r) {
  return r.keys().map(r)
}
const listOfImages = importAll(
  require.context("../../images/gallery", false, /\.(jpg)$/)
)

const sizeClasses = ["wide", "heigh", "wider"]

const getClass = () => {
  return sizeClasses[Math.floor(Math.random() * 4)]
}

const GalleryGrid = styled.ul`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  --size: 25vw;
  --gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(var(--size) - var(--gap) * 2), 1fr)
  );
  grid-template-rows: auto;
  gap: var(--gap);
  align-items: stretch;

  list-style: none;

  li {
    display: block;
    height: calc(var(--size) - var(--gap) * 2);
    max-width: 100%;
  }

  li img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const GenerateGallery = () => {
  return listOfImages.map((img, id) => (
    <li className={`${getClass()}`}>
      <img
        key={img}
        src={img}
        class={`p-1 bg-white shadow rounded`}
        alt="img"
      />
    </li>
  ))
}

const Gallery = () => {
  return (
    <Layout>
      <SEO title="Gallery | Savage Outdoor & Defense" />
      <div class="p-8 bg-gray-800">
        <div class="max-w-full mx-auto">
          <div class="flex justify-center items-center content-center text-center">
            <div class="text-center">
              <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate ">
                Gallery
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="my-4 px-4 ">
          <div class=" ">
            {/* <div class="flex flex-wrap gap-4 flex-grow mx-auto"> */}
            <GalleryGrid>
              <GenerateGallery />
            </GalleryGrid>
          </div>
          {/* </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default Gallery
